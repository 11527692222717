<script>
import {comment} from "@/domain/tarefas/helpers/mockComment"
import ECol from "components/layout/components/Col.vue"
import SlEditor from "components/layout/components/Editor"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import TaskAttachments from "components/suporte/components/task/include/attachment/Attachments.vue"
import ERow from "components/layout/components/Row.vue"
import {createComment} from "@/domain/tarefas/services"

export default {
  name: "TaskReply",
  components: {ERow, TaskAttachments, ErpSField, SlEditor, ECol},
  props: {
    task: Object
  },
  data () {
    return {
      comment: JSON.parse(JSON.stringify(comment)),
      textEditing: false,
      sending: false
    }
  },
  methods: {
    async send () {
      if (this.sending) return
      console.log('Send')
      if (String(this.$refs.editor.contentNode().textContent).trim().length === 0) {
        this.$uloc.notify({
          color: 'black',
          message: `Digite algo para seu comentário.`
        })
        return
      }
      const comment = this.$refs.editor.htmlContent()
      const marks = []
      const marksNodes = this.$refs.editor.contentNode().querySelectorAll('[data-person-mark-id]')
      if (marksNodes) {
        for (let i = 0; i < marksNodes.length; i++) {
          marks.push(marksNodes[i].dataset.personMarkId)
        }
      }
      this.sending = true
      createComment(this.task, {
        text: comment,
        marks: marks
      }, true)
          .then(async response => {
            // HistoryState.listen && HistoryState.listen(response.data.id)
            if (this.$refs.attachments.existsFilesToUpload) {
              this.$refs.attachments.enableGerencia()
              this.$refs.attachments.defineComment(response.data.id)
              await this.$refs.attachments.iniciaEnvios()
            }
            this.$uloc.notify({color: 'positive', position: 'top-right', message: 'Resposta ao caso registrada com sucesso!'})
            this.comment.text = ''
            this.sending = false
            this.$emit('update', response.data)
          })
          .catch(error => {
            this.sending = false
            this.alertApiError(error)
          })
    },
  }
}
</script>

<template>
  <div class="cli-task-reply suporte-chamado-window e-input-modern">
    <e-row class="m-t-sm" mr>
      <e-col>
        <erp-s-field label="Resposta" icon-help="Não compartilhe informações confidenciais em correspondências de casos, como credenciais, cartões de crédito, URLs assinados ou informações de identificação pessoal." required>
          <div class="task-editor">
            <!--        <text-editor toolbar-position="top-outside"/>-->
            <sl-editor
                ref="editor"
                v-model="comment.text"
                :show-toolbar-on-focus="true"
                :close-click-outside="false"
                disable-mark
                disable-source
                disable-print
                autofocus
                @focus="() => { textEditing = true }"
            />
          </div>
        </erp-s-field>
      </e-col>
    </e-row>
    <e-row class="m-t" mr>
      <e-col>
        <task-attachments ref="attachments" :task="task" :autosend="false" disable-load-files />
      </e-col>
    </e-row>
    <div class="flex justify-end">
      <u-btn @click="$emit('close')" label="Cancelar" color="white" class="text-black b-radius-3px font-13" style="border: #b9b9b9 1px solid" no-caps />
      <u-btn @click="send" label="Enviar" color="orange-9" class="text-black b-radius-3px font-13 m-l-sm" style="font-weight: bold" no-caps />
    </div>
  </div>
</template>
