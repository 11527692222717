export default {
    name: 'SuporteClienteMixin',
    provide: function () {
        return {
            suportePage: this
        }
    },
    data() {
        return {
            isSuporte: true
        }
    },
    inject: {
        erplayout: {
            default() {
                console.error('Main needs to be child of ErpLayout')
            }
        }
    },
    beforeMount() {
    },
    mounted() {
    },
    destroyed() {
    },
    methods: {
    }
}
