<template>
  <div class="task-group-persons">
    <div @click="click(person)" v-for="person in persons" :key="person.id"
         class="person-avatar avatar inline hover-scale min">
      <u-tooltip :offset="[5,5]">{{ person.name }}</u-tooltip>
      <img v-if="person.photo" width="30" class="" :src="person.photo"/>
      <div v-else class="img-fake">{{ firstLettersName(person.name) }}</div>
    </div>
  </div>
</template>

<script>
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import {UTooltip} from "uloc-vue"

export default {
  name: "TaskAllPersons",
  props: {
    task: Object
  },
  computed: {
    persons () {
      const persons = []
      this.task.author && persons.push(this.task.author)
      this.task.assigned && persons.push(this.task.assigned)
      this.task.participants && this.task.participants.map(p => {
        !persons.find(f => f.id === p.person.id) && persons.push(p.person)
      })
      this.task.followers && this.task.followers.map(p => {
        !persons.find(f => f.id === p.person.id) && persons.push(p.person)
      })
      return persons
    }
  },
  components: {
    UTooltip
  },
  methods: {
    click (person) {

      if (this.emitClick) {
        this.$emit('click', person)
      }
    }
  }
}
</script>

