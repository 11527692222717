<template>
  <div v-if="person" class="cli-person-profile">
    <div v-if="!disablePhoto" class="person-avatar avatar inline hover-scale min">
      <u-tooltip :offset="[5,5]">{{ person.name }}</u-tooltip>
      <img v-if="person.photo" width="30" class="" :src="person.photo"/>
      <div v-else class="img-fake">{{ firstLettersName(person.name) }}</div>
    </div>
    <div v-if="!disableName">{{ firstLastname(person.name) }}</div>
  </div>
  <div v-else class="person-profile">
    -
  </div>
</template>

<script>
// import TaskMixin from "components/suporte/components/task/include/TaskMixin"

export default {
  name: "PersonProfile",
  props: {
    person: {
      type: Object
    },
    disablePhoto: {
      type: Boolean,
      default: false
    },
    disableName: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  mounted() {
  }
}
</script>
